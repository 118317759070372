<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Reimbursement #<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.sequence }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Date</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.date }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Name<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.employeeName }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Division</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.division }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>PO Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.poNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>WO Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.woNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Project</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.project }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Payment</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.methodPayment }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" v-if="form.methodPayment === 'Transfer'">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Account Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ accountNumber(form.accountNumberId) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Currency</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.currency }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs vertical>
            <v-tab background-color="light-blue accent-3" dark class="px-0">
              <v-icon>
                mdi-note-text
              </v-icon>
            </v-tab>
            <v-tab-item>
              <v-card flat class="px-2">
                <v-row>
                  <v-col cols="6"> </v-col>
                  <v-col cols="6" align-self="start" class="text-right pb-0">
                    <p class="headline font-italic">Requirement <span class="red--text">*</span></p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="form.items"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:item.no="{ item }">
                        {{ form.items.map(x => x).indexOf(item) + 1 }}
                      </template>
                      <template v-slot:item.amount="{ item }">
                        {{ formatPrice(item.amount) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-card outlined>
            <v-card-title class="py-0">Total Reimbursement</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(form.total) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-replacement-cost",
  props: {
    form: Object,
    id: Number,
    listAccountNumber: Array,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Requirement",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    accountNumber(id) {
      let result = this.listAccountNumber.find(x => x.id === id);
      return result ? result.accountNumber : "";
    },
  },
};
</script>

<style></style>
