<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="replacementCost"
          :listAccountNumber="listAccountNumber"
          :listCategory="listCategory"
          :dropDownCurrency="dropDownCurrency"
          :employees="employees"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail
          :id="id"
          :form="replacementCost"
          :listAccountNumber="listAccountNumber"
          v-if="edit"
        ></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="replacementCost.formNumber"
      title="Replacement Cost"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailReplacementCost";
import FormEdit from "./FormReplacementCost";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "replacement-cost-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    listCategory: [],
    listGeneralKasbon: [],
    replacementCost: {},
  }),
  computed: {
    ...mapState("generalKasbon", ["listAccountNumber"]),
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("external", ["employees"]),
  },
  methods: {
    async getSettlementKasbon() {
      await this.$store.dispatch("replacementCost/getById", this.id).then(response => {
        this.replacementCost = response.data;
        this.$store.dispatch("generalKasbon/getListAccountNumber", response.data.employeeId);
      });
      this.getListCategory();
      this.$store.dispatch("external/getEmployees");
      this.$store.dispatch("currency/getListCurrency");
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("settlementKasbon/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getSettlementKasbon();
      this.edit = true;
    },
    async getListCategory() {
      const response = await this.$store.dispatch("generalKasbon/getListCategory");
      if (response.status === 200) {
        this.listCategory = response.data;
      }
    },
  },
  mounted() {
    this.getSettlementKasbon();
  },
};
</script>

<style></style>
